export const allFirstDiv = "md:ml-16 bg-white min-h-screen font-Inter";
export const allSecondDiv =
  "flex flex-col gap-6 h-full bg-white m-2 p-5 md:m-12 md:p-12";
export const pageTitle =
  "text-[28px] font-Inter text-transparent bg-clip-text bg-gradient-to-b from-[#214DED] to-[#B92F2C] md:font-extrabold font-bold pb-5 border-b-2 m-0 pt-2";

export const generalInput =
  "input input-bordered input-info shadow-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light";
export const monthNumberToAbbreviation = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const hoverScale = "hover:scale-105 transition-transform duration-300";
export const borderStyle = "border-2 border-solid border-base-200";
export const modalButton =
  "text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-3 py-1";

export const generalEditInputUser =
  "input input-bordered input-info shadow-sm text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light";
