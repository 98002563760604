import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { hoverScale, modalButton } from "../../../components/DesignStandardize";
import { toast } from "react-toastify";
import axios from "axios";
import { format } from "date-fns";

const AdminEditLeaveApplication = ({ id, dates }) => {
  console.log(id);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState({});
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const calculateTotalDates = (start, end) => {
    if (!start || !end) return 0;

    let totalDays = 0;

    // Iterate over each day in the range
    let currentDate = new Date(start);
    while (currentDate <= end) {
      
     
        totalDays++;
      
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return totalDays;
  };



  const onChange = (dates) => {
    const [start, end] = dates;

    // Check if the selected range exceeds 3 days
    if (start && end) {
      const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      if (daysDifference > 3) {
        // If more than 3 days are selected, reset the dates
        setStartDate(null);
        setEndDate(null);
        return;
      }
    }
    setStartDate(start);
    setEndDate(end);

    const newTotalDates = calculateTotalDates(start, end);

    // Store selected dates in an array
    const datesArray = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      
        datesArray.push(new Date(currentDate));
      
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // Convert selectedDates to ISO 8601 format with Bangladesh Standard Time (GMT+6) offset
    const isoDatesArray = datesArray.map((date) =>
      moment(date).tz("Asia/Dhaka").format()
    );
    // console.log(isoDatesArray);
    setData({
      ...data,
      Date: JSON.stringify(isoDatesArray),
      Total: newTotalDates,
    });
  };

  const updateLeaveApplication = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apiUrl}/leave/updateLeave/${id}`,
        data
      );
      console.log(response.data);
      toast.success("Leave Application Updated Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.success("Oops! There is an error in updating");
      // console.log(error);
    }
  };

  console.log("dates:", dates);
  const initiallyAppliedDates = JSON.parse(dates).map((date) => new Date(date));
  return (
    <form
      onSubmit={updateLeaveApplication}
      className="flex flex-col gap-3 items-center"
    >
      <p className="text-blue-900 text-lg mr-auto flex flex-col gap-1">
        Initially applied date:
        <ul className="flex flex-col gap-1 text-2xl">
          {JSON.parse(dates).map((date, index) => (
            <li key={index}>
              {index + 1}.{" "}
              <span className="font-semibold">
                {format(new Date(date), "yyyy-MM-dd")}
              </span>
            </li>
          ))}
        </ul>
      </p>

      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        dateFormat="yyyy-MM-dd"
        showTimeSelect={false}
        highlightDates={initiallyAppliedDates}
      />

      <button
        type="submit"
        className={`${modalButton} ${hoverScale} py-2 mx-auto w-max m-2`}
      >
        Submit
      </button>
    </form>
  );
};

export default AdminEditLeaveApplication;
